<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal'
import { useMouse, useMousePressed } from '@vueuse/core'
import type { IForecastAssumption } from '@reports'

const props = withDefaults(
  defineProps<{ assumptions: IForecastAssumption[]; loading: boolean }>(),
  {
    assumptions: () => [],
    loading: false,
  },
)

const emit = defineEmits<{
  (e: 'close'): void
}>()

const router = useRouter()
const route = useRoute()
const divisionId = getCurrentDivisionId()

// SELECTED
const selectedId = useRouteQuery('assumptionId', undefined)
const forceSelected = useRouteQuery('force', undefined)
const selected = computed(() =>
  props.assumptions.find((a) => a.leaseId === selectedId.value),
)
const displayAssumption = computed(() => {
  if (selected.value) return selected.value
  if (props.assumptions.length === 1) return props.assumptions[0]
  return undefined
})
const canViewAssumption = computed(() => {
  const leaseIds = route.query.leaseIds as string[] | undefined
  const isEmpty = !leaseIds || leaseIds.length === 0
  return isEmpty || leaseIds.length > 1
})

// CLEAR
const clear = () => {
  selectedId.value = undefined
  forceSelected.value = undefined
}

// GO BACK
const onGoBack = () => clear()

// CLOSE
const onClose = (shouldClear = true) => {
  if (shouldClear) clear()
  emit('close')
}
// Close on route change
const removeGuard = router.beforeEach((to, from) => {
  if (to.path !== from.path) {
    onClose(false)
  }
})
onUnmounted(() => {
  removeGuard()
})

// SCROLL TO TOP
const scrollRef = ref<HTMLElement | null>(null)
watch(selected, () => {
  setTimeout(() => {
    scrollRef.value
      ?.getElementsByClassName('simplebar-content-wrapper')[0]
      ?.scrollTo({ top: 0, behavior: 'smooth' })
  }, 500)
})

// RESIZE
const resizerRef = ref<HTMLElement | null>(null)
const drawerWidth = useState<string>('drawer:forecast-width', () => '460px')

const { width: windowWidth } = useWindowSize()
const { x } = useMouse()
const { pressed } = useMousePressed({ target: resizerRef })
const isResizing = ref(false)

watch(pressed, (newPressed) => {
  isResizing.value = newPressed
  if (newPressed) {
    document.body.style.userSelect = 'none'
  } else {
    document.body.style.userSelect = ''
  }
})

watchEffect(() => {
  if (isResizing.value) {
    const newWidth = window.innerWidth - x.value
    const maxWidth = windowWidth.value - 180
    const minWidth = 300
    drawerWidth.value = `${Math.max(minWidth, Math.min(newWidth, maxWidth))}px`
  }
})
</script>

<template>
  <VueFinalModal
    :esc-to-close="true"
    background="interactive"
    :lock-scroll="false"
    :reserve-scroll-bar-gap="true"
    :hide-overlay="true"
    content-transition="vfm-slide-right"
    overlay-transition="vfm-fade"
    @keydown.esc="onClose()"
  >
    <div
      :style="{ width: drawerWidth }"
      class="absolute bottom-0 right-0 top-0 h-screen max-w-full rounded-r-none border-l border-gray-800 bg-gray-900/80 shadow-2xl backdrop-blur-2xl backdrop-saturate-[180%]"
    >
      <!-- Resizer -->
      <div
        ref="resizerRef"
        :class="{
          '!bg-primary': isResizing,
        }"
        class="hover:bg-primary absolute bottom-0 left-0 top-0 z-[100] hidden w-1 cursor-col-resize bg-gray-800 hover:cursor-col-resize md:block"
      />

      <!-- Drawer title -->
      <div
        v-auto-animate
        class="standalone:top-safe-top standalone:pt-safe-top h-navigation sticky top-0 z-50 flex w-full content-center items-center bg-gray-900/60 px-6 shadow-lg"
      >
        <Button
          v-if="!!selected"
          color="secondary"
          class="mr-2 h-7 w-7 px-0 py-0"
          @click="onGoBack()"
        >
          <Icon name="chevron-left" />
        </Button>
        <h1 class="text-base">Lease details</h1>
        <Button
          color="secondary"
          class="ml-auto h-7 w-7 px-0 py-0"
          icon="close"
          @click="onClose()"
        >
        </Button>
      </div>

      <!-- Content -->
      <div
        v-auto-animate
        class="relative flex h-[calc(100vh-60px)] flex-1 grow gap-y-2"
      >
        <ReportsForecastDetails
          v-if="displayAssumption"
          v-model:viewing="forceSelected"
          :assumption="displayAssumption"
          :can-view="canViewAssumption"
          :division-id="divisionId"
        />

        <ReportsForecastDetailsList
          v-else
          :assumptions="assumptions"
          :loading="loading"
          @click="(assumption) => (selectedId = assumption.leaseId)"
        />
      </div>
    </div>
  </VueFinalModal>
</template>
